import Popup from './src/components/popup';

export const wrapPageElement = ({ element, props }) => {
  // Get the page path from the location
  const { location } = props;

  // Define the path where you want to conditionally render the component
  const conditionPath = 'homes/yew';

  return location.pathname.includes(conditionPath) ? (
    <>
      {element}
      <Popup
        body={
          <p>
            Our pre-sale program for flats at Yew, in the master planned
            community of Baden Park, has completed. We look forward to opening
            our doors onsite in late 2025 for our final homes. If you are
            interested in a future home, please register today to stay up to
            date and new information becomes available. Thank&nbsp;you!
          </p>
        }
      />
    </>
  ) : (
    element
  );
};
