import { useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';

const Popup = ({ body, className }) => {
  // get visited from local storage if it exists
  const visited =
    typeof window !== 'undefined' && sessionStorage.getItem('visited')
      ? true
      : false;

  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const closePopup = useCallback(() => {
    setIsPopupOpen(false);
    document.body.classList.remove('popup-open');
  }, []);

  const openPopup = useCallback(() => {
    setIsPopupOpen(true);
    document.body.classList.add('popup-open');
  }, []);

  useEffect(() => {
    let timeout;

    if (!visited) {
      // set popup to open after 100 milliseconds
      timeout = setTimeout(() => {
        openPopup();
        sessionStorage.setItem('visited', true);
      }, 400);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visited, openPopup]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closePopup();
      }
    };

    if (isPopupOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPopupOpen, closePopup]);

  return visited ? null : (
    <div
      className={classnames('popup-wrapper', {
        [className]: className,
        'popup-wrapper--open': isPopupOpen
      })}
    >
      <div className="popup">
        {body ? body : null}

        <button className="popup__close-btn" onClick={closePopup}>
          <svg
            className="close-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
          >
            <path d="M42.82,45L25,27.18,7.18,45l-2.18-2.18L22.82,25,5,7.18l2.18-2.18L25,22.82,42.82,5l2.18,2.18L27.18,25l17.82,17.82-2.18,2.18Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Popup;
